export type ProjectType =
  | "draft"
  | "publish"
  | "translation"
  | "setApplication"
  | "setEdition"
  | "setRegion"
  | "setLanguage"
  | "setPrice"
  | "setRatio"
  | "setLevel"
  | "setHashtag"
  | "setCategory"
  | "history";
export type CRUD = "READ" | "WRITE" | "DELETE";
export type RoleNames = "admin" | "operator" | "viewer";

// eslint-disable-next-line no-shadow
type Role = { [Key in RoleNames]: { [Key in CRUD]: Array<ProjectType> } };

export const ROLE: Role = {
  admin: {
    READ: [
      "draft",
      "publish",
      "translation",
      "setApplication",
      "setEdition",
      "setRegion",
      "setLanguage",
      "setPrice",
      "setRatio",
      "setLevel",
      "setHashtag",
      "setCategory",
      "history",
    ],
    WRITE: [
      "draft",
      "publish",
      "translation",
      "setApplication",
      "setEdition",
      "setRegion",
      "setLanguage",
      "setPrice",
      "setRatio",
      "setLevel",
      "setHashtag",
      "setCategory",
    ],
    DELETE: [
      "draft",
      "publish",
      "translation",
      "setApplication",
      "setEdition",
      "setRegion",
      "setLanguage",
      "setPrice",
      "setRatio",
      "setLevel",
      "setHashtag",
      "setCategory",
    ],
  },
  operator: {
    READ: [
      "draft",
      "publish",
      "translation",
      "setApplication",
      "setEdition",
      "setRegion",
      "setLanguage",
      "setPrice",
      "setRatio",
      "setLevel",
      "setHashtag",
      "setCategory",
      "history",
    ],
    WRITE: ["draft", "publish", "translation", "setLevel", "setCategory"],
    DELETE: ["draft", "publish", "translation", "setLevel", "setCategory"],
  },
  viewer: {
    READ: [
      "draft",
      "publish",
      "translation",
      "setApplication",
      "setEdition",
      "setRegion",
      "setLanguage",
      "setPrice",
      "setRatio",
      "setLevel",
      "setHashtag",
      "setCategory",
      "history",
    ],
    WRITE: [],
    DELETE: [],
  },
};
