import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkToken } from "../utils/nauth";
// import UnauthPage from "./UnauthPage";

export const PrivateRoute = ({ children }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  useEffect(() => {
    if (!localStorage.getItem("role")) {
      checkToken(query.get("ntoken"));
    }
  }, [query]);
  return children;
};

export default PrivateRoute;
