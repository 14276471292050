import {
  S3Client,
  ListObjectsV2Command,
  PutObjectCommand,
  DeleteObjectCommand,
} from "@aws-sdk/client-s3";

const s3ClientWWWResource = new S3Client({
  region: process.env.REACT_APP_AWS_REGION || "",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_WWW_RESOURCE_ACCESS_KEY_ID || "",
    secretAccessKey:
      process.env.REACT_APP_AWS_WWW_RESOURCE_SECRET_ACCESS_KEY || "",
  },
});

const s3ClientResource = new S3Client({
  region: process.env.REACT_APP_AWS_REGION || "",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_RESOURCE_ACCESS_KEY_ID || "",
    secretAccessKey: process.env.REACT_APP_AWS_RESOURCE_SECRET_ACCESS_KEY || "",
  },
});

export const getContents = async (pathname: string, prefix: string) => {
  const s3Client =
    pathname === "/www-resource" ? s3ClientWWWResource : s3ClientResource;
  return await s3Client.send(
    new ListObjectsV2Command({
      Bucket:
        pathname === "/www-resource"
          ? process.env.REACT_APP_AWS_WWW_RESOURCE_BUCKET_NAME
          : process.env.REACT_APP_AWS_RESOURCE_BUCKET_NAME,
      Prefix: prefix,
      Delimiter: "/",
    })
  );
};

export const uploadContents = async (
  pathname: string,
  key: string,
  file: File
) => {
  const s3Client =
    pathname === "/www-resource" ? s3ClientWWWResource : s3ClientResource;
  const command = new PutObjectCommand({
    Bucket:
      pathname === "/www-resource"
        ? process.env.REACT_APP_AWS_WWW_RESOURCE_BUCKET_NAME
        : process.env.REACT_APP_AWS_RESOURCE_BUCKET_NAME,
    Key: key,
    Body: file,
    ContentType: file.type,
  });
  return await s3Client.send(command);
};

export const deleteContents = async ({
  pathname,
  key,
}: {
  pathname: string;
  key: string;
}) => {
  const s3Client =
    pathname === "/www-resource" ? s3ClientWWWResource : s3ClientResource;

  console.log("deleteContents 1", pathname);
  console.log("deleteContents 2", key);

  const command = new DeleteObjectCommand({
    Bucket:
      pathname === "/www-resource"
        ? process.env.REACT_APP_AWS_WWW_RESOURCE_BUCKET_NAME
        : process.env.REACT_APP_AWS_RESOURCE_BUCKET_NAME,
    Key: key,
  });

  return await s3Client.send(command);
};
