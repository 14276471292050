type TStorageKey =
  | "kine_app"
  | "recent_search"
  | "prev_date"
  | "like_projects"
  | "select_type"
  | "search_hashtag"
  | "env"
  | "language"
  | "edition"
  | "application"
  | "commentStorage"
  | "email"
  | "name"
  | "ntoken"
  | "role"
  | "location"
  | "searchComment"
  | "listType"
  | "currentPath";

export const getStorage = (key: TStorageKey) => {
  return window.localStorage.getItem(key);
};

export const setStorage = (key: TStorageKey, val: string) => {
  window.localStorage.setItem(key, val);
};

export const resetStorage = (key: TStorageKey) => {
  window.localStorage.removeItem(key);
};

// select_type 일 경우 hashtag(디테일 해시태그 선택), search(search 페이지 에서 검색)
export const setSelectType = (val: "hashtag" | "search" | "feed") => {
  window.localStorage.setItem("select_type", val);
};

// 최근 검색어 불러오기
export const getSearchList = () => {
  return getStorage("recent_search")?.split(",");
};

// 최근 검색어 저장하기 (중복 제거)
export const setSearchList = (value: any) => {
  const replaceValue = value.replace(/,/gi, "comma2c");
  const originSearchList = getSearchList();
  const newSearchList = originSearchList
    ? Array.from(new Set([replaceValue, ...originSearchList]))
    : [replaceValue];

  // 30개 이상 저장 못하게 막음
  if (newSearchList.length >= 30) {
    newSearchList.splice(30, newSearchList.length);
  }
  setStorage("recent_search", newSearchList.join(","));
};

// 좋아요 id 불러오기
export const getLikeProjects = () => {
  const likeProjects = getStorage("like_projects")?.split(",");
  return likeProjects || [];
};

// 좋아요 id 추가
export const setAddLikeProjects = (id: string) => {
  const originLikeProjects = getLikeProjects();
  const newLikeProjects = [...originLikeProjects, id];
  setStorage("like_projects", newLikeProjects.join(","));
};

// 좋아요 id 삭제
export const setDeleteLikeProjects = (id: string) => {
  const originLikeProjects = getLikeProjects();
  const newLikeProjects = originLikeProjects.filter(
    (projectId) => projectId !== id
  );
  setStorage("like_projects", newLikeProjects.join(","));
};
