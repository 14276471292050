import {
  CloudFrontClient,
  CreateInvalidationCommand,
  GetInvalidationCommand,
} from "@aws-sdk/client-cloudfront";

const cfWWWResourceClient = new CloudFrontClient({
  region: process.env.REACT_APP_AWS_REGION || "",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_WWW_RESOURCE_ACCESS_KEY_ID || "",
    secretAccessKey:
      process.env.REACT_APP_AWS_WWW_RESOURCE_SECRET_ACCESS_KEY || "",
  },
});

const cfResourceClient = new CloudFrontClient({
  region: process.env.REACT_APP_AWS_REGION || "",
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_RESOURCE_ACCESS_KEY_ID || "",
    secretAccessKey: process.env.REACT_APP_AWS_RESOURCE_SECRET_ACCESS_KEY || "",
  },
});

export const invalidateCache = async (
  pathname: string,
  prefix: string,
  filename: string
) => {
  console.log("invalidateCache", pathname, prefix, filename);
  console.log(`/${prefix}${filename}`);
  const cfClient =
    pathname === "/www-resource" ? cfWWWResourceClient : cfResourceClient;
  const command = new CreateInvalidationCommand({
    DistributionId:
      pathname === "/www-resource"
        ? process.env.REACT_APP_CLOUDFRONT_WWW_RESOURCE_DISTRIBUTION_ID
        : process.env.REACT_APP_CLOUDFRONT_RESOURCE_DISTRIBUTION_ID,
    InvalidationBatch: {
      Paths: {
        Quantity: 1,
        Items: [`/${prefix}${filename}`],
      },
      CallerReference: `invalidation-${Date.now()}`,
    },
  });
  return await cfClient.send(command);
};
