import axios from "axios";
import { getItem } from "../utils/nauth";

type method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type url = string;
type data = {
  [key: string]: any;
};
type config = {
  [key: string]: any;
};

/**
 * Common Axios Method
 * @param method get | post | put | delete
 * @param url Request를 요청할 URL String
 * @param data 전달할 Data Object (value : string type)
 * @param config 추가 설정 정보
 *
 * @returns Result Promise (then, catch로 분기 처리)
 */

const Axios = async (
  method: method,
  url: url,
  data?: data,
  config?: config
) => {
  const nToken = getItem("ntoken");

  const defaultConfig = {
    ...config,
    headers: {
      Authorization: `Bearer ${nToken}`,
      "Content-Type": `application/x-www-form-urlencoded`,
      ...config?.headers,
    },
  };

  switch (method) {
    case "POST": {
      try {
        const response = await axios.post(url, data, defaultConfig);
        return response;
      } catch (error) {
        return error;
      }
    }

    case "PUT": {
      try {
        const response = await axios.put(url, data, defaultConfig);
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    }

    case "PATCH": {
      try {
        const response = await axios.patch(url, data, defaultConfig);
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    }

    case "DELETE": {
      try {
        const response = await axios.delete(url, defaultConfig);
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    }

    default: {
      try {
        if (data) {
          const queryString = new URLSearchParams(
            JSON.parse(JSON.stringify(data))
          ).toString();
          const response = await axios.get(
            `${url}?${queryString}`,
            defaultConfig
          );
          return response.data;
        } else {
          const response = await axios.get(`${url}`, defaultConfig);
          return response.data;
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    }
  }
};

export default Axios;
