import { getItem } from "../utils/nauth";
// import {
//   AccountServerType,
//   DomainType,
//   ServerType,
//   ServiceType,
//   SectionServerType,
// } from "./types";
// import { Cookies } from "react-cookie";

const LOCAL_URL = "http://localhost:3000/";
const TEST_URL = "https://test-s3-console.kinemaster.com";
const PROD_URL = "https://s3-console.kinemaster.com";

/**
 * nAuth Token 값을 스토리지에서 받아와서 리턴
 * @returns token : string
 */
export const getToken = () => {
  return getItem("ntoken");
};

/**
 * 현재 도메인 값으로 Upload API BaseUrl 을 리턴
 * @returns baseUrl : string
 */
// export const getBaseUrl = () => {
//   const CONSTANTS = {
//     LOCAL_API_DOMAIN: "https://test-api-sku-console.kinemasters.com",
//     // LOCAL_API_DOMAIN: "https://dev-api-woojin.kinemasters.com",
//     TEST_API_DOMAIN: "https://test-api-sku-console.kinemasters.com",
//     PROD_API_DOMAIN: "https://api-sku-console.kinemasters.com",
//   };

//   const { href } = window.location;

//   // const cookies = new Cookies();
//   // const getCookie = (item: string) => {
//   //   return cookies.get(item);
//   // };

//   if (href.includes(LOCAL_URL)) {
//     return CONSTANTS.LOCAL_API_DOMAIN;
//   }
//   if (href.includes(TEST_URL)) {
//     return CONSTANTS.TEST_API_DOMAIN;
//   }
//   if (href.includes(PROD_URL)) {
//     return CONSTANTS.PROD_API_DOMAIN;
//   }

//   return "DOMAIN_NOT_FOUND";
// };

/**
 * 현재 도메인 값으로 History API BaseUrl 을 리턴
 * @returns baseUrl : string
 */
export const getHistoryBaseUrl = () => {
  const CONSTANTS = {
    LOCAL_API_DOMAIN: "https://test-api-console-history.kinemasters.com",
    TEST_API_DOMAIN: "https://test-api-console-history.kinemasters.com",
    PROD_API_DOMAIN: "https://api-console-history.kinemasters.com",
  };

  const { href } = window.location;

  if (href.includes(LOCAL_URL)) {
    return CONSTANTS.LOCAL_API_DOMAIN;
  }
  if (href.includes(TEST_URL)) {
    return CONSTANTS.TEST_API_DOMAIN;
  }
  if (href.includes(PROD_URL)) {
    return CONSTANTS.PROD_API_DOMAIN;
  }

  return "DOMAIN_NOT_FOUND";
};

/**
 * 현재 도메인값으로 Server Type 을 리턴
 * @returns serverType : string
 */
export const getServerType = () => {
  const CONSTANTS = {
    LOCAL_SERVER_TYPE: "dev",
    TEST_SERVER_TYPE: "test",
    PROD_SERVER_TYPE: "prod",
  };

  const { href } = window.location;

  if (href.includes(LOCAL_URL)) {
    return CONSTANTS.LOCAL_SERVER_TYPE;
  } else if (href.includes(TEST_URL)) {
    return CONSTANTS.TEST_SERVER_TYPE;
  } else if (href.includes(PROD_URL)) {
    return CONSTANTS.PROD_SERVER_TYPE;
  } else {
    return "";
  }
};

/**
 * 현재 도메인값으로 Service Name 을 리턴
 * @returns serviceName : string
 */

export const getServiceName = () => {
  const CONSTANTS = {
    LOCAL_SERVICE_NAME: "dev-s3-console",
    TEST_SERVICE_NAME: "test-s3-console",
    PROD_SERVICE_NAME: "prod-s3-console",
  };

  const { href } = window.location;

  if (href.includes(LOCAL_URL)) {
    return CONSTANTS.LOCAL_SERVICE_NAME;
  }
  if (href.includes(TEST_URL)) {
    return CONSTANTS.TEST_SERVICE_NAME;
  }
  if (href.includes(PROD_URL)) {
    return CONSTANTS.PROD_SERVICE_NAME;
  }

  return "not found";
};
