import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

type DatePickerType = {
  label: string;
  date: Date;
  setDate: (date: Date) => void;
  maxDate?: Date;
  minDate?: Date;
};

function DatePicker({
  label,
  date,
  setDate,
  maxDate,
  minDate,
}: DatePickerType) {
  const [value, setValue] = useState<Date | null>(date);

  const onChange = (newValue: Date | null) => {
    setValue(newValue);
    if (newValue !== null) {
      setDate(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        label={label}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
        format="yyyy/MM/dd"
        slotProps={{
          textField: {
            size: "small",
            inputProps: {
              readOnly: true,
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default DatePicker;
