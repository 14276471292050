import React, { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import theme from "../../styles/theme";
import GlobalStyle from "../../styles/GlobalStyles";

interface GlobalLayoutProps {
  children: ReactNode;
}

export const StyleProvider = ({ children }: GlobalLayoutProps) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};
