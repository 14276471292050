import React, { ReactNode } from "react";
import { useLocation } from "react-router";
import Menu, { MenuBaseProps } from "./Menu";
import { Container } from "./styles";

export interface SideBarProps {
  menuList?: MenuBaseProps[];
  children?: ReactNode;
}

export const SideBar = ({ menuList, children }: SideBarProps) => {
  const { pathname } = useLocation();
  const isSelected = (path: string) => {
    return pathname.includes(path);
  };

  return (
    <Container>
      {menuList &&
        menuList.map((menu) => (
          <Menu key={menu.title} {...menu} isSelected={isSelected(menu.path)} />
        ))}
      {children}
    </Container>
  );
};

export default SideBar;
