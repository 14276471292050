import { useQuery } from "@tanstack/react-query";
import { getContents } from "../api/s3/s3APIs";
import { setStorage } from "../utils/storage";

const excludeRegex = new RegExp(
  process.env.REACT_APP_EXCLUDE_PATTERN || /^\/$/
);

const listContents = async (pathname, prefix) => {
  // console.debug("Retrieving data from AWS SDK");
  // console.log("pathname", pathname);
  // console.log("prefix", prefix);
  const data = await getContents(pathname, prefix);
  // console.debug(`Received data: ${JSON.stringify(data, null, 2)}`);
  return {
    folders:
      data.CommonPrefixes?.filter(
        ({ Prefix }) => !excludeRegex.test(Prefix)
      ).map(({ Prefix }) => ({
        name: Prefix.slice(prefix.length),
        path: Prefix,
        url: `/?prefix=${Prefix}`,
      })) || [],
    objects:
      data.Contents?.filter(({ Key }) => {
        return !excludeRegex.test(Key) && Key.slice(prefix.length) !== "";
      }).map(({ Key, LastModified, Size }) => ({
        name: Key.slice(prefix.length),
        lastModified: LastModified,
        size: Size,
        path: Key,
        url: `https://${pathname}.kinemaster.com/${Key}`,
      })) || [],
  };
};

export const useContents = (pathname, prefix) => {
  // console.log("useContents pathname", pathname);
  // console.log("useContents prefix", prefix);
  setStorage("currentPath", JSON.stringify({ pathname, prefix }));
  return useQuery({
    queryKey: ["contents", pathname, prefix],
    queryFn: () => listContents(pathname, prefix),
  });
};
