import React, { useState } from "react";
import { getItem } from "../../utils/nauth";
import {
  AppBar,
  Toolbar,
  Typography,
  NameBadge,
  Box,
  SubTypography,
  Menu,
  MenuContainer,
  MenuTitleText,
} from "./styles";
import { MenuItem } from "@mui/material";
import Modal from "@mui/material/Modal";

export interface HeaderProps {
  server: string | "test" | "development" | "production" | "";
  label: string;
  isLogin: boolean;
  onClick: () => void;
  logoutHandler: () => void;
}

export const Header = ({
  server,
  label,
  logoutHandler,
  isLogin,
  onClick,
  ...rest
}: HeaderProps) => {
  const userRole = getItem("role");
  const outPutServerName = () => {
    if (server === "test") {
      return "test";
    } else if (server === "dev") {
      return "dev";
    } else if (server === "prod") {
      return "prod";
    } else {
      return "";
    }
  };

  const [openModal, setOpenModal] = useState<boolean>(false);

  const MenuButtonHandler = () => {
    return openModal ? setOpenModal(false) : setOpenModal(true);
  };

  return (
    <AppBar {...rest} position="static">
      <Toolbar>
        <Box>
          <Typography variant="h5" onClick={onClick}>
            {label}
            <NameBadge>{outPutServerName()}</NameBadge>
          </Typography>
        </Box>

        <Box>
          <SubTypography
            sx={{ mr: "30px", color: "#D0BCFF", fontSize: "13.5px" }}
          >
            Role : {userRole}
          </SubTypography>

          {isLogin && (
            <SubTypography
              sx={{ cursor: "pointer", color: "#D0BCFF", fontSize: "13.5px" }}
              onClick={logoutHandler}
            >
              Log out
            </SubTypography>
          )}
          <Menu onClick={MenuButtonHandler} />

          <Modal open={openModal} onClose={MenuButtonHandler}>
            <MenuContainer>
              <MenuTitleText onClick={onClick}>
                KineMaster Console :: S3 Console{" "}
              </MenuTitleText>

              <MenuItem
                sx={{ mt: "5px", color: "#D0BCFF", fontSize: "13.5px" }}
              >
                Role : {userRole}
              </MenuItem>
              {isLogin && (
                <MenuItem
                  sx={{ mt: "5px", fontSize: "13.5px", color: "#D0BCFF" }}
                  onClick={logoutHandler}
                >
                  Log out
                </MenuItem>
              )}
            </MenuContainer>
          </Modal>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
