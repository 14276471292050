import { useState, useEffect } from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import Explorer from "./components/Explorer";
import { StyleProvider } from "./components/StyleProvider";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import { MenuList } from "./components/SideBar/menuList";
import { Box } from "@mui/material";
import History from "./pages/History";
import Explorer from "./pages/Explorer";
import { getServerType } from "./constants/Constants";
import PrivateRoute from "./components/PrivateRoute";
import { checkAuth, signOut } from "./utils/nauth";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const headerClickHandler = () => {
    window.location.href = "/";
  };
  useEffect(() => {
    setIsLogin(checkAuth());
    return () => {
      queryClient.clear();
    };
  }, []);

  return (
    <StyleProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter
          future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
        >
          <Header
            label="KineMaster Console :: S3 Console"
            server={getServerType()}
            isLogin={isLogin}
            logoutHandler={signOut}
            onClick={headerClickHandler}
          />
          <Box sx={{ display: "flex" }}>
            <SideBar menuList={MenuList} />
            <Box sx={{ flex: 1 }}>
              <Routes>
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <Navigate to="/resource" />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/resource"
                  element={
                    <PrivateRoute>
                      <Explorer pathname="/resource" />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/www-resource"
                  element={
                    <PrivateRoute>
                      <Explorer pathname="/www-resource" />
                    </PrivateRoute>
                  }
                />
                {/* <Route
                  path="/history"
                  element={
                    <PrivateRoute>
                      <History />
                    </PrivateRoute>
                  }
                /> */}
              </Routes>
            </Box>
          </Box>
        </BrowserRouter>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </StyleProvider>
  );
}

export default App;
