import React, { useState, useEffect } from "react";
import { Box, Typography, Divider, Button, Stack } from "@mui/material";
import DatePicker from "../components/DatePicker";
import { getItem } from "../utils/nauth";
import dayjs from "dayjs";
import { useSuspenseQuery } from "@tanstack/react-query";
import { DataGrid } from "@mui/x-data-grid";
import { UtcDateFormatter } from "../utils/dateformat/dateFormat";
import { getHistorys } from "../api/History";
import { SuspenseAndErrorBoundary } from "../components/SuspenseAndErrorBoundary";

const fnGetHistorys = async (
  startDate: any,
  endDate: any,
  page: any,
  display: any,
  user: any
) => {
  try {
    const data = await getHistorys(
      undefined,
      startDate,
      endDate,
      page,
      display,
      user
    );
    return data;
  } catch (err) {
    console.error("fnGetHistorys: ", err);
  }
};

// // Create a custom component that renders the button and the modal
// const OpenModalButton = ({ row }: any) => {
//   const [open, setOpen] = React.useState(false);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };
//   return (
//     <>
//       <Button onClick={handleOpen}>Open Details</Button>
//       <JsonCompare
//         open={open}
//         onOpen={handleOpen}
//         onClose={handleClose}
//         row={row}
//       />
//     </>
//   );
// };

const HistoryList = ({
  startDate,
  endDate,
  page,
  display,
  user,
  setPage,
  setDisplay,
}: any) => {
  const { isLoading, data } = useSuspenseQuery({
    queryKey: ["getHistorys", startDate, endDate, page, display, user],
    queryFn: () => fnGetHistorys(startDate, endDate, page, display, user),
  });

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: display,
  });

  useEffect(() => {
    setPage(paginationModel.page + 1);
    setDisplay(paginationModel.pageSize);
  }, [paginationModel]);

  const usersTableColumns = [
    {
      field: "id",
      headerName: "No",
      width: 80,
      headerClassName: "super-app-theme--header",
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params: any) => {
        return UtcDateFormatter(params.formattedValue);
      },
      sortable: false,
    },
    {
      field: "ipAddress",
      headerName: "IP Address",
      width: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "user",
      headerName: "Edited by",
      width: 200,
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "details",
      headerName: "Details",
      flex: 2,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params: any) => {
        if (params.formattedValue.action === "Login")
          return (
            <div>
              <strong>Action:</strong> {params.formattedValue.action}
            </div>
          );
        if (params.formattedValue.action === "Logout")
          return (
            <div>
              <strong>Action:</strong> {params.formattedValue.action}
            </div>
          );
        if (params.formattedValue.action === "Modified SKU") {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Stack>
                <div>
                  <strong>Action:</strong> {params.formattedValue.action}
                </div>
                <div>
                  <strong>AppName:</strong>{" "}
                  {params.formattedValue.details.appName}
                </div>
                <div>
                  <strong>Edition:</strong>{" "}
                  {params.formattedValue.details.edition}
                </div>
                <div>
                  <strong>SKU ID:</strong> {params.formattedValue.details.id}
                </div>
              </Stack>
              {/* <OpenModalButton row={params.formattedValue} /> */}
            </div>
          );
        } else if (params.formattedValue.action === "Add Application") {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Stack>
                <div>
                  <strong>Action:</strong> {params.formattedValue.action}
                </div>
                <div>
                  <strong>AppName:</strong>{" "}
                  {params.formattedValue.details.appName}
                </div>

                <div>
                  <strong>Edition:</strong>{" "}
                  {params.formattedValue.details.edition}
                </div>
                <div>
                  <strong>SKU ID:</strong> {params.formattedValue.details.id.id}
                </div>
                <div>
                  <strong>Type:</strong> {params.formattedValue.details.type}
                </div>
              </Stack>
            </div>
          );
        } else if (params.formattedValue.action === "Delete Application") {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Stack>
                <div>
                  <strong>Action:</strong> {params.formattedValue.action}
                </div>
                <div>
                  <strong>SKU ID:</strong> {params.formattedValue.details.id}
                </div>
              </Stack>
            </div>
          );
        } else if (params.formattedValue.action === "Modified Application") {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <strong>Action:</strong> {params.formattedValue.action}
              </div>
              <div>
                <strong>SKU ID:</strong> {params.formattedValue.details.id}
              </div>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Stack>
                  <strong>key</strong>
                  <div>
                    <strong>AppName</strong>
                  </div>
                  <div>
                    <strong>Edition</strong>
                  </div>
                  <div>
                    <strong>Application</strong>
                  </div>
                </Stack>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Stack>
                  <strong>Before</strong>
                  <div>{params.formattedValue.details.before.appName}</div>
                  <div>{params.formattedValue.details.before.edition}</div>
                  <div>{params.formattedValue.details.before.application}</div>
                </Stack>
                <Divider orientation="vertical" variant="middle" flexItem />
                <Stack>
                  <strong>After</strong>
                  <div>{params.formattedValue.details.after.appName}</div>
                  <div>{params.formattedValue.details.after.edition}</div>
                  <div>{params.formattedValue.details.after.application}</div>
                </Stack>
              </div>
            </div>
          );
        } else
          return <pre>{JSON.stringify(params.formattedValue, null, 2)}</pre>;
        // return (
        //   <div style={{ display: "flex", flexDirection: "column" }}>
        //     <div>
        //       <strong>Action:</strong> {params.formattedValue.action}
        //     </div>
        //     <OpenModalButton row={params.formattedValue} />
        //   </div>
        // );
        // }
      },
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "rgb(204, 204, 204)",
        },
      }}
    >
      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        columns={usersTableColumns}
        rows={data.history.map((item: any, index: any) => ({
          id: (page - 1) * display + index + 1,
          ...item,
        }))}
        rowCount={data.totalCount}
        getRowId={(row) => row.historyId}
        pageSizeOptions={[10, 20, 50]}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        sx={{
          "& .MuiDataGrid-cell": {
            borderTop: "none",
          },
          "& .MuiDataGrid-row": {
            border: "1px solid lightgray",
            alignItems: "center",
            padding: "10px 0px",
          },
        }}
      />
    </Box>
  );
};

const History = () => {
  const [startDate, setStartDate] = useState(
    dayjs().startOf("day").subtract(1, "w").toDate()
  );
  const [endDate, setEndDate] = useState(dayjs().endOf("day").toDate());
  const [page, setPage] = useState(1);
  const [display, setDisplay] = useState(20);
  const user = getItem("role") === "admin" ? "all" : getItem("email");
  const isViewer = getItem("role") === "viewer";

  return (
    <Box sx={{ maxWidth: "4xl", m: 3, mt: 1 }}>
      <Stack spacing={2}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h5">History</Typography>
        </Box>
        <Divider />
        <Divider />
        {isViewer ? (
          <Box
            sx={{
              mt: "20px",
              mb: "20px",
              display: "flex",
            }}
          >
            <Typography textAlign={"center"}>
              You do not have permission to view the history.
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                mt: "20px",
                mb: "20px",
                display: "flex",
                justifyContent: "flex-start",
                height: 50,
              }}
            >
              <DatePicker
                label="Start Date"
                date={startDate}
                setDate={setStartDate}
                maxDate={endDate}
              />
              &nbsp;&nbsp;&nbsp;
              <DatePicker
                label="End Date"
                date={endDate}
                setDate={setEndDate}
                maxDate={dayjs().toDate()}
                minDate={startDate}
              />
            </Box>
            <SuspenseAndErrorBoundary>
              <HistoryList
                startDate={startDate}
                endDate={endDate}
                page={page}
                display={display}
                user={user}
                setPage={setPage}
                setDisplay={setDisplay}
              />
            </SuspenseAndErrorBoundary>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default History;
