/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable default-param-last */
import Axios from "./Axios";
import { getHistoryBaseUrl, getServiceName } from "../constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const baseUrl = getHistoryBaseUrl();
let serviceName: string;
const getHistoryUrl = "/v1/history";

const config = {
  headers: {
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
    "Content-type": "application/json",
  },
};

/**
 * [GET] Historys API
 *
 * @param user 제목
 * @param from 날짜 시작 범위
 * @param to 날짜 종료 범위
 * @param page 페이지 번호
 * @param display 호출하는 게시물의 갯수
 * @param user 사용자 검색
 *
 * @returns Promise
 */
export const getHistorys = async (
  templatesId?: string,
  from?: Date,
  to?: Date,
  page: number = 1,
  display: number = 20,
  user?: string
) => {
  const startedAt = dayjs(from).startOf("d").utc(true).format();
  const endedAt = dayjs(to).endOf("d").utc(true).format();
  if (templatesId) serviceName = `${templatesId}_${getServiceName()}`;
  else serviceName = getServiceName();
  const method = "GET";
  const data = {
    serviceName,
    page,
    display,
    startedAt,
    endedAt,
    user,
  };

  const url = `${baseUrl}${getHistoryUrl}`;

  return Axios(method, url, data, config);
};

/**
 * [POST] Historys API
 *
 * @param userName 요청자 이름
 * @param details 상세내용
 * @param createdAt 요청 날짜
 *
 * @returns Promise
 */
export const postHistory = async (
  user: string,
  details: object,
  templatesId?: string
) => {
  const method = "POST";

  if (templatesId) serviceName = `${templatesId}_${getServiceName()}`;
  else serviceName = getServiceName();

  const data = {
    serviceName,
    user,
    details,
  };

  const url = `${baseUrl}${getHistoryUrl}`;

  return Axios(method, url, data, config);
};
