import styled from "styled-components";
import { Box as MuiBox, Typography as MuiTypography } from "@mui/material";

export const Container = styled(MuiBox)`
  &.MuiBox-root {
    padding: 12px 0;
    width: 256px;
    min-width: 256px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Box = styled(MuiBox)`
  &.MuiBox-root {
    padding: 16px;
    width: 100%;
    max-height: 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    cursor: pointer;
    border-radius: 0px 40px 40px 0px;
  }
  &.MuiBox-root.selected {
    background: linear-gradient(
        0deg,
        rgba(103, 80, 164, 0.05),
        rgba(103, 80, 164, 0.05)
      ),
      #fffbfe;
  }
`;

export const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #79747e;
  }

  &.MuiTypography-root.selected {
    color: #6750a4;
  }
`;

export const Wrap = styled(MuiBox)`
  &.MuiBox-root {
    width: 100%;
    height: calc(100vh - 32px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const MenuWrap = styled(MuiBox)`
  &.MuiBox-root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    gap: 16px;
  }
`;

export const AddOnBox = styled(MuiBox)`
  &.MuiBox-root {
    width: calc(100% - 32px);
    padding: 16px;
    text-align: left;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #79747e;
  }
`;
