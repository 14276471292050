import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

export const UtcDateFormatter = (string : string) => {
  dayjs.extend(utc);
  if (!string) {
    return "";
  }
  return `${dayjs(string).utc().format("YYYY-MM-DD HH:mm:ss")} (UTC)`;
};

export const setStartFormat = (date : Date) => {
  dayjs.extend(utc);
  return dayjs(date).utc(true).startOf('date').toDate();
};

export const setEndFormat = (date : Date) => {
  dayjs.extend(utc);
  return dayjs(date).utc(true).endOf('date').toDate();
};
