import React from "react";
import { useNavigate } from "react-router";
import { Box, Typography } from "./styles";

export interface MenuBaseProps {
  title: string;
  path: string;
  action?: () => void;
}

export interface MenuProps extends MenuBaseProps {
  isSelected: boolean;
}

const Menu = ({ title, path, isSelected, action }: MenuProps) => {
  const className = isSelected ? "selected" : "";
  const navigate = useNavigate();
  const onClick = (event: React.MouseEvent) => {
    if (action) {
      event.preventDefault();
      action();
    } else {
      navigate(path);
    }
  };

  return (
    <Box className={className} onClick={onClick}>
      <Typography className={className}>{title}</Typography>
    </Box>
  );
};

export default Menu;
