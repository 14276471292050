import React from "react";
import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolBar,
  Typography as MuiTypography,
  Box as MuiBox,
} from "@mui/material";
import Select from "@mui/material/Select";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";

export const AppBar = styled(MuiAppBar)`
  &.MuiPaper-root {
    position: relative;
    box-shadow: none;
  }
  .MuiToolbar-root {
    background-color: #6750a4;
    height: 64px;
    .MuiToolbar-root {
      min-height: 64px;
    }
  }
`;

export const Toolbar = styled(MuiToolBar)`
  &.MuiToolbar-root {
    display: flex;
    justify-content: space-between;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
`;

export const Typography = styled(MuiTypography)`
  &.MuiTypography-root {
    position: relative;
    display: block;
  }
`;

export const SubTypography = styled(MuiTypography)`
  &.MuiTypography-root {
    position: relative;
    display: block;
  }

  @media screen and (max-width: 600px) {
    display: none !important;
  }
`;

export const NameBadge = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -24px;
  display: block;
  font-size: 12px;
`;

export const Box = styled(MuiBox)`
  minwidth: 200px;
  display: flex;
`;

export const Menu = styled(MenuIcon)`
  cursor: pointer;

  @media screen and (min-width: 600px) {
    display: none !important;
  }
`;

export const MenuTitleText = styled(MuiTypography)`
  cursor: pointer;
  color: black !important;
  font-size: 16px !important;
`;

export const MenuContainer = styled(MuiBox)`
  background-color: #fffbfe;
  width: 85vw;
  height: calc(100vh - 12px);
  padding: 12px 12px 0 12px;
  border: none !important;

  @media screen and (min-width: 600px) {
    display: none !important;
  }
`;

export const RoleTypography = styled(MuiTypography)`
  position: absolute;
  top: 16px;
  right: 105px;
  display: block;
  font-size: 13px !important;
`;
