import { MenuBaseProps } from "./Menu";

export const MenuList: MenuBaseProps[] = [
  {
    title: "resource",
    path: "/resource",
  },
  {
    title: "www-resource",
    path: "/www-resource",
  },
  // {
  //   title: "History",
  //   path: "/history",
  // },
];
