import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link as ReactRouterLink, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import {
  Home as HomeIcon,
  Folder as FolderIcon,
  Description as DocumentIcon,
  NavigateNext as NavigateNextIcon,
  CloudUpload as CloudUploadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  Breadcrumbs,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Link as MuiLink,
  Button,
} from "@mui/material";
import { useContents } from "../hooks/useContents";
import { sanitizePrefix, formatFileSize } from "../helpers";
import BtnInvalidate from "./BtnInvalidate";
import Progress from "./Progress";
import { uploadContents, deleteContents } from "../api/s3/s3APIs";
import { useQueryClient } from "@tanstack/react-query";
import { invalidateCache } from "../api/CloudFront/cfAPIs";

export default function Explorer({ pathname }) {
  // console.log("Explorer pathname", pathname);
  const [searchParams] = useSearchParams();
  const prefix = sanitizePrefix(searchParams.get("prefix") || "");
  const [isUploading, setIsUploading] = useState(false);

  // console.log("prefix", prefix);
  // console.log("searchParams", searchParams);
  // console.log("pathname", pathname);

  useEffect(() => {
    document.title = "S3 Console :: KineMaster";
  }, []);

  // if (pathname === "/resource") {
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       height="100%"
  //     >
  //       Under Construction
  //     </Box>
  //   );
  // }

  return (
    <Box sx={{ maxWidth: "4xl", m: 3, mt: 1 }}>
      <Stack spacing={2}>
        <Navigation pathname={pathname} prefix={prefix} />
        <Listing
          pathname={pathname}
          prefix={prefix}
          setIsUploading={setIsUploading}
        />
        <UploadButton
          pathname={pathname}
          prefix={prefix}
          setIsUploading={setIsUploading}
        />
      </Stack>
      {isUploading && <Progress />}
    </Box>
  );
}

const fnUpload = async (pathname, prefix, file) => {
  console.log("file", file.webkitRelativePath, file.name, prefix);
  const fileName = file.webkitRelativePath
    ? file.webkitRelativePath
    : file.name;

  const key = prefix ? `${prefix}${fileName}` : fileName;
  const result = await uploadContents(pathname, key, file);
  console.log("uploadFile", file, prefix, pathname, result);
  if (result.$metadata.httpStatusCode === 200) {
    const cfResult = await invalidateCache(pathname, prefix, fileName);
    return {
      filename: fileName,
      status: result.$metadata.httpStatusCode,
      cfStatus: cfResult.$metadata.httpStatusCode,
    };
  } else {
    return {
      filename: fileName,
      status: false,
      cfStatus: false,
    };
  }
};

function UploadButton({ pathname, prefix, setIsUploading }) {
  const queryClient = useQueryClient();

  const handleFileUpload = async (files) => {
    if (files?.length > 0) {
      setIsUploading(true);
      const uploads = Array.from(files)
        .filter((file) => file.name !== ".DS_Store")
        .map((file) => {
          return fnUpload(pathname, prefix, file);
        });
      await Promise.all(uploads)
        .then(async (results) => {
          // console.log("uploadFile results", results);
          alert(
            `Upload Results\n${results
              .map(
                (result) =>
                  `${result.filename} : ${
                    result.status === 200
                      ? result.cfStatus === 201
                        ? "Successful (CloudFront Invalidated)"
                        : "Successful"
                      : "Failed"
                  }`
              )
              .join("\n")}`
          );
        })
        .catch((error) => {
          alert(`Upload failed\\n ${error.message}`);
        })
        .finally(() => {
          setIsUploading(false);
          queryClient.invalidateQueries({
            queryKey: ["contents", pathname, prefix],
          });
        });
    }
  };

  return (
    <Box sx={{ mt: 2, display: "flex", gap: 2, justifyContent: "flex-end" }}>
      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        size="small"
      >
        Upload Files
        <input
          type="file"
          hidden
          multiple
          onChange={async (e) => await handleFileUpload(e.target.files)}
        />
      </Button>

      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        size="small"
      >
        Upload Folder
        <input
          type="file"
          hidden
          webkitdirectory=""
          directory=""
          mozdirectory=""
          multiple
          onChange={async (e) => {
            if (
              window.confirm("Are you sure you want to upload this folder?")
            ) {
              await handleFileUpload(e.target.files);
            }
          }}
        />
      </Button>
    </Box>
  );
}

function DeleteButton({ name, prefix, pathname, setIsUploading }) {
  const queryClient = useQueryClient();

  return null;

  const handleDelete = async () => {
    setIsUploading(true);
    await deleteContents({ pathname, key: `${prefix}${name}` })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ["contents", pathname, prefix],
        });
      })
      .catch((error) => {
        alert(`Delete failed: ${error.message}`);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <Button
      variant="contained"
      startIcon={<DeleteIcon />}
      size="small"
      onClick={() => {
        const isFolder = name.includes("/");
        if (
          window.confirm(
            `Are you sure you want to delete ${
              isFolder ? `${name.replace("/", "")} folder` : name
            }?`
          )
        ) {
          handleDelete();
        }
      }}
    >
      Delete
    </Button>
  );
}

function Navigation({ pathname, prefix }) {
  // console.log("Navigation prefix", prefix, pathname);
  const folders = prefix
    .split("/")
    .slice(0, -1)
    .map((item, index, items) => ({
      name: `${item}`,
      url: `${pathname}?prefix=${items.slice(0, index + 1).join("/")}/`,
      isCurrent: index === items.length - 1,
    }));

  const breadcrumbItems = [
    folders.length === 0 ? (
      <Typography key="home" color="text.secondary">
        <HomeIcon sx={{ mr: 1, verticalAlign: "middle" }} />
        {pathname.replace("/", "")}
      </Typography>
    ) : (
      <MuiLink
        key="home"
        component={ReactRouterLink}
        to=""
        aria-label="bucket root"
      >
        <HomeIcon sx={{ verticalAlign: "middle" }} />
      </MuiLink>
    ),
    ...folders.map((item) =>
      item.isCurrent ? (
        <Typography key={item.url} color="text.secondary">
          {item.name}
        </Typography>
      ) : (
        <MuiLink key={item.url} component={ReactRouterLink} to={item.url}>
          {item.name}
        </MuiLink>
      )
    ),
  ];

  return (
    <Box sx={{ p: 3, bgcolor: "grey.100", borderRadius: 1, boxShadow: 1 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        {breadcrumbItems}
      </Breadcrumbs>
    </Box>
  );
}

Navigation.propTypes = {
  pathname: PropTypes.string,
  prefix: PropTypes.string,
};

function Listing({ pathname, prefix, setIsUploading }) {
  const { status, data, error } = useContents(pathname, prefix);
  return (
    <>
      {/* <Typography variant="h4" sx={{ my: 2, fontWeight: 300 }}>
        {prefix
          ? `${prefix.split("/").slice(-2, -1)}`
          : process.env.REACT_APP_BUCKET_NAME}
      </Typography> */}
      <Box sx={{ borderRadius: 1, boxShadow: 1 }}>
        <Table size="small">
          <TableHead sx={{ bgcolor: "grey.200" }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Last modified</TableCell>
              <TableCell>Size</TableCell>
              <TableCell align="right">Function</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(() => {
              switch (status) {
                case "loading":
                  return (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                        Loading...
                      </TableCell>
                    </TableRow>
                  );
                case "error":
                  return (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        Failed to fetch data: {error.message}
                      </TableCell>
                    </TableRow>
                  );
                case "success":
                  return (
                    <>
                      {data?.folders.map((item) => (
                        <TableRow key={item.path}>
                          <TableCell>
                            <FolderIcon
                              sx={{ mr: 1, verticalAlign: "text-top" }}
                            />
                            <MuiLink
                              component={ReactRouterLink}
                              to={`${pathname}?prefix=${item.path}`}
                            >
                              {item.name.replace(/\/$/, "")}
                            </MuiLink>
                          </TableCell>
                          <TableCell>–</TableCell>
                          <TableCell>–</TableCell>
                          <TableCell align="right" gap={1}>
                            <Box
                              display="flex"
                              gap={1}
                              justifyContent="flex-end"
                            >
                              <DeleteButton
                                name={item.name}
                                prefix={prefix}
                                pathname={pathname}
                                setIsUploading={setIsUploading}
                              />
                              <BtnInvalidate
                                name={`${item.name}*`}
                                prefix={prefix}
                                pathname={pathname}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                      {data?.objects.map((item) => (
                        <TableRow key={item.path}>
                          <TableCell>
                            <DocumentIcon
                              sx={{ mr: 1, verticalAlign: "text-top" }}
                            />
                            <MuiLink
                              href={item.url}
                              target="_blank"
                              rel="noopener"
                            >
                              {item.name}
                            </MuiLink>
                          </TableCell>
                          <TableCell>
                            {item.lastModified.toLocaleString()}
                          </TableCell>
                          <TableCell>{formatFileSize(item.size)}</TableCell>
                          <TableCell align="right">
                            <Box
                              display="flex"
                              gap={1}
                              justifyContent="flex-end"
                            >
                              <DeleteButton
                                name={item.name}
                                prefix={prefix}
                                pathname={pathname}
                                setIsUploading={setIsUploading}
                              />
                              <BtnInvalidate
                                name={item.name}
                                prefix={prefix}
                                pathname={pathname}
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}

Listing.propTypes = {
  pathname: PropTypes.string,
  prefix: PropTypes.string,
  setIsUploading: PropTypes.func,
};
