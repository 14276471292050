import reset from "styled-reset";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle` 
@font-face {
  font-family: NotoSans;
  font-weight: 400;
  src: url(${require("./fonts/NotoSans-Regular.otf")}) format("truetype");
}
@font-face {
  font-family: NotoSans;
  font-weight: 500;
  src: url(${require("./fonts/NotoSans-Medium.otf")}) format("truetype");
}
@font-face {
  font-family: NotoSans;
  font-weight: 700;
  src: url(${require("./fonts/NotoSans-Bold.otf")}) format("truetype");
}
  ${reset};
  * {
    box-sizing: border-box;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, sub, sup, tt, var,
  u, i, center, dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video , button, input,
  select, textarea {
    font-family: SF Display Pro, Helvetica Neue, Helvetica, Arial,
            Sans-Serif;
    word-break: break-all;
  }
  html {
    height:100%;
  }
  body {
    height:100%
  }
  a {
      color: inherit;
      text-decoration: none;
  }
  input,
  button {
      &:focus,
      &:active{ outline: none }
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input::-ms-clear,
  input::-ms-reveal{
    display:none;width:0;height:0;
  }
  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration{
    display:none;
  }
  strong {
    font-weight: 500;
  }
  b {
    font-weight: bold;
  }
  input,
  textarea {
    -webkit-user-select: auto;
  }
  #root {
    height:100%
  }
  .console-menu-wrap{
    position:fixed;
    top:64px;
    left:0;
    width:100%;
    display:flex;
    height:48px;
    z-index:5000;
    justify-content: flex-start;
    align-items: center;
    background-color:#fff;
    a{
      width:200px;
      text-align:center;
      font-size:16px;
      font-weight:500;
      color:#79747E;
      display:block;
      line-height:48px;
    }
    .selected-link {
      color:#6750A4;
      position:relative;
      
    }
    .selected-link:before{
      display:block;
      width:100%;
      height:3px;
      background-color:#6750A4;
      content:"";
      position:absolute;
      bottom:0;
      left:0;
    }
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {border-color:#6750a4!important}
  .MuiPopover-root{z-index:1500!important}
  .MuiDataGrid-root .MuiDataGrid-columnsContainer {
    position: static !important;
  }
  .MuiDataGrid-root .MuiDataGrid-window {
    position: static !important;
  }
  .MuiDataGrid-menu{
    .MuiList-root li{
      &:nth-child(1){display:none}
      &:nth-child(2){display:none}
      &:nth-child(3){display:none}
      &:nth-child(4){display:none}
    }
  }
  .none-scroll{
    -ms-overflow-style: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
 }
 .none-scroll::-webkit-scrollbar{
   display:none;
 }
 
 // select-menu
.MuiMenuItem-root[role="option"]{
  // font-size:12px!important
}
 //CalendarHeader

 .MuiPickersArrowSwitcher-button{
   color:#6a6a6a!important;
 }
 .MuiButtonBase-root[aria-label="calendar view is open, switch to year view"]{
   color:#6a6a6a!important
 }
 .search-comment{
  border:4px solid red;
  
}
// date range picker 관련 
.open-cal{
  display:block!important;
  border: 1px solid #ccc;
  position: absolute;
          top: 40px;
          left: 0;
          zIndex: 1;
}
.rdrDateRangePickerWrapper{
  flex-direction:row-reverse;
}
`;

export default GlobalStyle;
