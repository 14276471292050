import { useState } from "react";
import { Button } from "@mui/material";
// import {
//   CloudFrontClient,
//   CreateInvalidationCommand,
// } from "@aws-sdk/client-cloudfront";
import RefreshIcon from "@mui/icons-material/Refresh";
import { invalidateCache } from "../api/CloudFront/cfAPIs";

export default function BtnInvalidate({ prefix, name, pathname }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleInvalidate = async () => {
    console.log(prefix, name, pathname);
    setIsLoading(true);
    try {
      const response = await invalidateCache(pathname, prefix, name);
      if (
        response.$metadata.httpStatusCode === 201 ||
        response.$metadata.httpStatusCode === 200 ||
        response.Invalidation.Status === "Completed"
      ) {
        alert("CloudFront Invalidation successful.");
      } else {
        alert("CloudFront Invalidation failed.");
      }
    } catch (error) {
      console.error("CloudFront Invalidation Error:", error);
      alert("CloudFront Invalidation failed.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      startIcon={<RefreshIcon />}
      size="small"
      onClick={handleInvalidate}
      disabled={isLoading}
    >
      Invalidate
    </Button>
  );
}
