import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#f9d4d4",
      main: "#6750A4",
      dark: "#6750A4",
    },
    error: {
      main: "#ff5252",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          lineHeight: "auto",
          textTransform: "initial",
          borderRadius: "25px",
          padding: "11px 14px",
          minWidth: "114px",
          "&.sizeSmall": {
            borderRadius: "16px",
            padding: "6px 14px",
            minWidth: "72px",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: "#6750A4 !important",
          },
          "&:active": {
            backgroundColor: "#6750A4 !important",
          },
          "&:disabled": {
            color: "#fff !important",
            backgroundColor: "#d2d2d3 !important",
          },
        },
        containedSecondary: {
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.3) !important",
          },
          "&:active": {
            backgroundColor: "rgba(255, 255, 255, 0.1) !important",
          },
          "&:disabled": {
            color: "#fff !important",
            backgroundColor: "#d2d2d3 !important",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        // icon 색상이 기본 #fff 되는것을 삭제
        // root: {
        //   color: "#fff",
        // },
        colorSecondary: {
          color: "rgba(255, 255, 255, 0.3)",
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorSecondary: {
          color: "#fff",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          // color: "#d2d2d3",
          // backgroundColor: "rgba(255, 255, 255, 0.05)",
          pointerEvents: "inherit",
        },
        label: {
          overflow: "visible",
          fontSize: "15px",
          padding: "0 20px !important",
        },
      },
    },
  },
  typography: {
    fontFamily: "inherit",
    h1: {
      fontSize: "60px",
      fontWeight: 300,
    },
    h2: {
      fontSize: "48px",
      fontWeight: 400,
    },
    h3: {
      fontSize: "34px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "24px",
      fontWeight: 500,
    },
    h5: {
      fontSize: "20px",
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "17px",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "15px",
      fontWeight: 500,
    },
    body1: {
      fontSize: "17px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "15px",
      fontWeight: 400,
    },
    caption: {
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "auto",
    },
  },
});

export default theme;
