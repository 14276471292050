import { jwtDecode } from "jwt-decode";
import { ROLE, RoleNames } from "./nauthType";
import { getStorage } from "./storage";
// import { postHistory } from "../api/History";

export interface IKineToken {
  ldap_idx: string;
  raw_id: string;
  result: string;
  role_name: string;
  role_priority: string;
  service_idx: string;
  service_name: string;
  user_email: string;
  user_id: string;
  user_idx: string;
  user_name: string;
  user_type: string;
}

const storage = localStorage;
// const storage = sessionStorage;

export function getItem(key: string) {
  return storage.getItem(key);
}

function getJwtDecode(ntoken: string) {
  const decodedToken = jwtDecode<IKineToken>(ntoken);
  return decodedToken;
}

// function getPathFromUrl(url: string) {
//   return url.split(/[?#]/)[0];
// }

export function checkToken(ntoken: string | null) {
  if (ntoken) {
    const decodedToken = getJwtDecode(ntoken);
    if (
      !!decodedToken &&
      decodedToken.user_name &&
      decodedToken.role_name
      // && decodedToken.user_email
    ) {
      storage.setItem("ntoken", ntoken);
      const expired = new Date();
      expired.setHours(expired.getHours() + 3);
      storage.setItem("expired", String(expired.getTime()));
      storage.setItem("name", decodedToken.user_name);
      decodedToken.user_email &&
        storage.setItem("email", decodedToken.user_email);
      storage.setItem("role", decodedToken.role_name);

      // postHistory(
      //   decodedToken.user_email
      //     ? decodedToken.user_email
      //     : decodedToken.user_name,
      //   {
      //     action: "Login",
      //   }
      // );
      window.location.href = storage.getItem("location") || "";

      return decodedToken;
    } else {
      console.log("[Error] ", decodedToken);
    }
    // window.location.href = window.location.origin + window.location.pathname;
  } else {
    storage.setItem("location", window.location.href);
    window.location.href = "https://auth.nexstreaming.com/auth";
  }
}

export default function getAuth(key: string) {
  const today = new Date().getTime();
  const expired = Number(storage.getItem("expired"));
  if (storage.getItem("expired")) {
    if (today > expired) {
      signOut();
    } else {
      return storage.getItem(key);
    }
  } else {
    return null;
  }
}

export function signOut() {
  // postHistory(eUploadedAction.Logout);
  // postHistory(getStorage("email") || getStorage("name") || "", {
  //   action: "Logout",
  // });
  storage.removeItem("ntoken");
  storage.removeItem("expired");
  storage.removeItem("name");
  storage.removeItem("email");
  storage.removeItem("role");
  storage.clear();
  checkToken(null);
}

export function checkAuth() {
  const role = getAuth("role") as RoleNames;
  if (Object.keys(ROLE).includes(role)) {
    const expired = new Date();
    expired.setHours(expired.getHours() + 3);
    localStorage.setItem("expired", String(expired.getTime()));
    return true;
  } else {
    return false;
    return false;
  }
}
